<template>
  <div>
    <el-container>
      <el-main>
        <head-layout
          head-title="费用基础信息"
          :head-btn-options="headTopBtnOptions"
          @head-cancel="handleHeadGoback"
          @head-save="headSave(1)"
          @head-save-back="headSave(2)"
        />
        <form-layout
          ref="formLayout"
          :column="formColumn"
          :dataForm.sync="dataForm"
        ></form-layout>
      </el-main>
    </el-container>
    <el-container>
      <el-main>
        <uploader
          title="凭证"
          @getTableData="handleUpload"
          :fileSize="500"
          accept=".jpg,.png,.pdf,.gif,.jpeg,.ppt,.doc,.docx,.xls,.xlsx"
          :multiple="true"
          :fileOptions="fileOptions"
          ref="uploader"
          :gridRowBtn="fileGridRowBtn"
          :disabled="type == 'view'"
        ></uploader>
      </el-main>
    </el-container>

    <template v-if="type !== 'add'">
      <el-container>
        <el-main>
          <head-layout
            head-title="支出明细"
            :head-btn-options="lineBtnOptions"
            @line-add="handleLineAdd"
          />
          <grid-layout
            ref="gridLayOut"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :data-total="page.total"
            :page="page"
            :editType="inline"
            @page-current-change="handleCurrentChange"
            @page-size-change="handleSizeChange"
            @page-refresh-change="onLoad"
            :grid-row-btn="gridRowBtn"
            @grid-row-detail-click="rowView"
            @row-edit="rowEdit"
            @row-remove="rowRemove"
          >
            <template #files="{ row }">
              <span>{{ row.files ? JSON.parse(row.files).length : 0 }}</span>
            </template>
          </grid-layout>
        </el-main>
      </el-container>
    </template>

    <el-dialog
      title="支出明细"
      :visible.sync="dialogVisible"
      width="50%"
      top="8vh"
    >
      <avue-form
        :option="formOption"
        ref="avueLineForm"
        v-model="lineForm"
        @submit="handleSubmit"
        v-loading="visibleLoading"
      >
        <template slot="subjectId">
          <el-cascader
            v-model="lineForm.subjectId"
            :options="casOptions"
            placeholder="请选择费用科目"
            :disabled="type == 'view' || isFormDisabled"
            ref="subjectCascader"
            style="width: 100%"
            :props="{ label: 'dictValue', value: 'dictKey', emitPath: false }"
            :show-all-levels="false"
            filterable
            @change="handleChange"
          ></el-cascader>
        </template>
        <template slot="costUserName">
          <el-input
            :disabled="type == 'view' || isFormDisabled"
            v-model="lineForm.costUserName"
            ref="lineUserName"
            clearable
            @clear="costUserClear"
            @focus="changeLineUser"
          ></el-input>
        </template>
        <template slot="costOrgName">
          <avue-input-tree
            v-if="lineForm.costUserId && lineForm.costUserId !== -1"
            default-expand-all
            :disabled="type == 'view' || isFormDisabled"
            v-model="lineForm.costOrgId"
            placeholder="请选择部门"
            @change="chooseDept"
            :props="{ label: 'title', value: 'id' }"
            :dic="deptList"
          ></avue-input-tree>
          <el-input
            :disabled="type == 'view' || isFormDisabled"
            v-else
            v-model="lineForm.costOrgName"
            ref="lineDeptName"
            @focus="changeLineDept"
          ></el-input>
        </template>
        <template slot="files">
          <el-upload
            :disabled="isFormDisabled"
            :class="{ uoloadSty: showImg, disUoloadSty: noneUploadFile }"
            action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
            accept=".jpg,.png,.pdf,.gif,.jpeg,.ppt,.doc,.docx,.xls,.xlsx"
            :limit="9"
            :file-list="fileArray"
            :on-success="uploadSuccess"
            :on-remove="handleRemove"
            :on-exceed="uploadExceed"
            :on-preview="handleFilePreview"
            :before-upload="handleBeforeUpload"
          >
            <el-button size="small"
              ><i class="el-icon-upload2"></i> 上传文件</el-button
            >
          </el-upload>
        </template>
      </avue-form>
      <template slot="footer" v-if="!isFormDisabled">
        <el-button
          size="small"
          @click="dialogVisible = false"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>

    <el-dialog title="人员选择" :visible.sync="userShow" width="80%" top="8vh">
      <UserDetpDialog
        ref="UserDetpDialog"
        @select-data="selectUserData"
      ></UserDetpDialog>
    </el-dialog>

    <el-dialog title="部门选择" :visible.sync="deptShow" width="80%">
      <DeptDialog ref="DeptDialog" @select-data="selectDept"></DeptDialog>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {
  costSubmit,
  costDetail,
  costLinePage,
  costLineSubmit,
  costLineRemove,
} from "@/api/responsibility/securityCost";
import { mapGetters } from "vuex";
import { downloadFileBlob } from "@/util/util";
import { dateFormat } from "@/util/date";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
import uploader from "@/components/file-upload/index";
import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog";
import { getDictionaryTreeCode } from "@/api/system/dictbiz.js";
import { nowUserDept } from "@/api/user";
import website from "@/config/website";
export default {
  components: {
    FormLayout,
    HeadLayout,
    GridLayout,
    UserDetpDialog,
    uploader,
    DeptDialog,
  },

  data() {
    return {
      nodeData: {},
      type: "",
      dataForm: {},
      // fileData: [],
      fileLoading: false,
      userShow: false,
      fileOptions: {
        linklabel: "name",
        select: false,
        column: [
          {
            label: "附件名",
            prop: "name",
            align: "left",
            overHidden: true,
          },
          {
            label: "附件格式",
            prop: "extension",
            align: "center",
            overHidden: true,
          },
          {
            label: "上传人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "上传时间",
            prop: "createTime",
            align: "center",
            overHidden: true,
          },
        ],
      },
      fileGridRowBtn: [
        {
          label: "下载",
          emit: "row-download",
        },
        {
          label: "删除",
          emit: "row-del",
        },
      ],
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      visibleLoading: false,
      lineForm: {
        cost: "",
        costDate: "",
        costHeadId: "",
        costOrgId: "",
        costOrgName: "",
        costUserName: "",
        costUserId: "",
        subjectId: "",
        subjectName: "",
        files: "",
      },
      casOptions: [], // 科目数据
      deptList: [],
      deptShow: false,
      dialogVisible: false,
      dialogLoading: false,
      isFormDisabled: false,
      noneUploadFile: false,
      fileArray: [],
      showFileDialog: false,
    };
  },

  created() {
    this.nodeData = this.$route.query.row
      ? JSON.parse(this.$route.query.row)
      : {};
    this.type = this.$route.query.type;
    if (this.nodeData.id) this.getDetail(this.nodeData.id);
    getDictionaryTreeCode("cost_subject").then((res) => {
      this.casOptions = res.data.data;
    });
  },

  mounted() {
    if (this.type == "view") {
      this.$refs.formLayout.$refs.form.allDisabled = true;
    }
  },

  computed: {
    ...mapGetters(["permission", "colorName"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduquestions_add, true),
        viewBtn: this.vaildData(this.permission.eduquestions_view, true),
        editBtn: this.vaildData(this.permission.eduquestions_edit, true),
        delBtn: this.vaildData(this.permission.eduquestions_delete, true),
      };
    },

    formColumn() {
      return [
        {
          label: "费用类别",
          prop: "costAim",
          labelWidth: 120,
          maxlength: 255,
          placeholder: "请填写",
          span: 8,
          rules: [
            {
              required: true,
              message: "请填写",
              trigger: "blur",
            },
          ],
        },
        {
          label: "记录时间",
          prop: "costDate",
          labelWidth: 120,
          placeholder: "请选择",
          span: 8,
          type: "date",
          format: "yyyy-MM-dd", // 设置日期格式
          valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
          value: dateFormat(new Date(), "yyyy-MM-dd"),
          // rules: [{
          //     required: true,
          //     message: "请填写",
          //     trigger: "change"
          // }]
        },
        {
          label: "支出总额",
          prop: "cost",
          labelWidth: 120,
          placeholder: "保存后填写支出明细自动计算",
          span: 8,
          disabled: true,
          // rules: [{
          //     required: true,
          //     message: "请填写",
          //     trigger: "blur",
          // }]
          // rules: [{
          //     validator: this.validateCost,
          // }]
        },
        {
          label: "备注",
          prop: "remark",
          labelWidth: 120,
          placeholder: "请输入描述",
          sortable: true,
          type: "textarea",
          overHidden: true,
          span: 24,
          minRows: "2",
          maxRows: "4",
          maxlength: 255,
          showWordLimit: true,
        },
      ];
    },

    headTopBtnOptions() {
      let buttonBtn = [];
      if (this.type != "view") {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
          loading: this.saveLoading,
        });
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-save-back",
          type: "button",
          icon: "",
          btnOptType: "saveBack",
          loading: this.saveLoading,
        });
      }
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.celBtn"),
        emit: "head-cancel",
        type: "button",
        icon: "",
      });
      return buttonBtn;
    },

    lineBtnOptions() {
      let buttonBtn = [];
      if (this.type != "view") {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "line-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
      }
      return buttonBtn;
    },

    tableOptions() {
      return {
        menu: this.type != "view",
        selection: false,
        index: true,
        indexLabel: "序号",
        linklabel: "subjectName",
        column: [
          {
            label: "科目",
            prop: "subjectName",
            align: "center",
            overHidden: true,
            dictUrl: "",
            props: {
              value: "dictKey",
              label: "dictValue",
            },
          },
          {
            label: "支出时间",
            prop: "costDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "金额",
            prop: "cost",
            align: "center",
            overHidden: true,
          },
          {
            label: "支出人",
            prop: "costUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "支出部门",
            prop: "costOrgName",
            align: "center",
            overHidden: true,
          },
          {
            label: "附件数量",
            prop: "files",
            slot: true,
            align: "center",
            overHidden: true,
          },
        ],
      };
    },

    formOption() {
      return {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "费用科目",
            prop: "subjectId",
            labelWidth: 100,
            placeholder: "请选择",
            span: 24,
            formsolt: true,
            disabled: this.isFormDisabled,
            rules: [
              {
                required: true,
                message: "请选择科目",
                trigger: "change",
              },
            ],
          },
          {
            label: "支出时间",
            labelWidth: 100,
            prop: "costDate",
            type: "date",
            format: "yyyy-MM-dd", // 设置日期格式
            valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
            span: 24,
            disabled: this.isFormDisabled,
            rules: [
              { required: true, message: "请选择支出时间", trigger: "change" },
            ],
          },
          {
            label: "支出金额",
            prop: "cost",
            type: "number",
            controls: false,
            labelWidth: 100,
            placeholder: "请填写",
            span: 24,
            disabled: this.isFormDisabled,
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "blur",
              },
            ],
          },
          {
            label: "支出人",
            prop: "costUserName",
            labelWidth: 100,
            disabled: this.isFormDisabled,
            formslot: true,
          },
          {
            label: "支出部门",
            prop: "costOrgName",
            labelWidth: 100,
            disabled: this.isFormDisabled,
            formslot: true,
          },
          {
            label: "附件",
            prop: "files",
            labelWidth: 100,
            span: 24,
            disabled: this.isFormDisabled,
            formslot: true,
          },
        ],
      };
    },

    gridRowBtn() {
      let btnList = [
        {
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        },
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: "",
        },
      ];
      return btnList;
    },
  },

  methods: {
    getDetail(id) {
      costDetail({ id }).then((res) => {
        const data = res.data.data;
        this.dataForm = data;
        // this.fileData = JSON.parse(data.files)
        this.$refs.uploader.initTaleData(data.files);
        this.onLoad(this.page);
      });
    },
    uploadExceed(limit, files, fileList, column) {
      this.$message.error("只能上传九个文件，请先删除当前文件");
    },
    // 上传之前的函数
    handleBeforeUpload(file) {
      let fileName = file.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length).toLowerCase();
      let arr = [
        ".jpg",
        ".png",
        ".pdf",
        ".gif",
        ".jpeg",
        ".ppt",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
      ];
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
        return false;
      }
      if (!arr.includes(lastName)) {
        this.$message.warning("不支持上传此类型文件");
        return false;
      }
    },
    // 上传成功
    uploadSuccess(response, file, fileList) {
      // fieldName 表单中向后端传递的字段名
      this.fileArray = fileList.map((item) => {
        if (item.response) {
          item.response.data.url = item.response.data.link;
          item.response.data.name = item.response.data.originalName;
          return item.response.data;
        } else {
          return item;
        }
      });
    },
    // 删除文件
    handleRemove(file, fileList) {
      this.fileArray = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
    },
    handleFilePreview(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    getLineData() {
      this.tableLoading = true;
    },

    handleHeadGoback() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headSave(val) {
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.dataForm.id)
            this.dataForm.belongOrgId = this.nodeData.belongOrgId;
          this.dataForm.cost = this.dataForm.cost ? this.dataForm.cost : "0.00";
          this.$loading();
          costSubmit({ ...this.dataForm }).then((res) => {
            if (val == 1 && !this.nodeData.id) {
              this.$router.$avueRouter.closeTag();

              this.$router.replace({
                path: "/business/responsibility/securityCost/edit",
                query: {
                  row: JSON.stringify({ id: res.data.data.id }),
                  type: "edit",
                },
              });
            }
            if (val == 2) {
              this.handleHeadGoback();
            }
            this.$loading().close();
          });
          this.$refs.formLayout.$refs.form.allDisabled = false;
        }
      });
    },

    handleUpload(data) {
      this.dataForm.files = this.resetFileData(data);
    },
    //   组合附件数据
    resetFileData(data) {
      let arr = [];
      data.forEach((ele, ind) => {
        arr.push({
          createTime: ele.createTime,
          createUser: ele.createUser,
          createUserName: ele.createUserName,
          extension: ele.extension,
          fileSize: ele.fileSize,
          id: ele.id,
          path: ele.path,
          schedule: ele.schedule,
          uid: ele.uid,
          name: ele.name,
          size: ele.size,
        });
      });
      return JSON.stringify(arr);
    },

    getDate(data, format) {
      return dateFormat(data, format);
    },

    handleLineAdd() {
      this.init({
        costDate: dateFormat(new Date(), "yyyy-MM-dd"),
        costHeadId: this.dataForm.id,
      });
      this.dialogVisible = true;
    },

    init(data) {
      for (let key in this.lineForm) {
        if (key in data) {
          this.lineForm[key] = data[key];
        } else {
          this.lineForm[key] = "";
        }
      }
      this.fileArray = this.lineForm.files
        ? JSON.parse(this.lineForm.files)
        : [];
    },

    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page);
    },

    onLoad(page) {
      this.tableLoading = true;
      this.page = page;
      costLinePage(page.currentPage, page.pageSize, {
        costHeadId: this.dataForm.id,
      }).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },

    rowEdit(row) {
      this.init(row);
      this.noneUploadFile = false;
      this.isFormDisabled = false;
      this.lineForm.id = row.id;
      if(this.lineForm.costUserId){
        nowUserDept({ userId: this.lineForm.costUserId }).then((res) => {
        if (res.data.code === 200) {
          this.deptList = res.data.data;
        }
      });
      }
      this.dialogVisible = true;
    },

    rowView(row) {
      this.init(row);
      this.noneUploadFile = true;
      this.isFormDisabled = true;
      this.dialogVisible = true;
    },

    rowRemove(row) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        costLineRemove(row.id).then(() => {
          this.onLoad(this.page);
          this.getDetail(this.nodeData.id);
          this.$message.success("操作成功！");
        });
      });
    },

    handleChange(e) {
      const node = this.$refs.subjectCascader.getCheckedNodes();
      this.lineForm.subjectId = node[0].value;
      this.lineForm.subjectName = node[0].label;
    },

    changeLineUser() {
      this.userShow = true;
      this.$refs.lineUserName.blur();
    },

    costUserClear() {
      this.lineForm.costUserId = "";
      this.lineForm.costUserName = "";
      this.lineForm.costOrgName = "";
      this.lineForm.costOrgId = "";
    },

    selectUserData(data, deptId, deptName) {
      this.lineForm.costUserId = data.id;
      this.lineForm.costUserName = data.realName;
      this.lineForm.costOrgName = deptName;
      this.lineForm.costOrgId = deptId;
      nowUserDept({ userId: data.id }).then((res) => {
        if (res.data.code === 200) {
          this.deptList = res.data.data;
        }
      });
      this.userShow = false;
    },

    chooseDept(e) {
      // this.lineForm.orgName = deptName
      this.lineForm.costOrgId = e;
      if (this.deptList.length) {
        this.lineForm.costOrgName = this.deptList.find(
          (val) => val.id == e
        ).title;
      }
    },

    changeLineDept() {
      this.$refs.lineDeptName.blur();
      this.deptShow = true;
    },

    selectDept(row) {
      this.lineForm.costOrgName = row.deptName;
      this.lineForm.costOrgId = row.id;
      this.deptShow = false;
    },

    handleSave() {
      this.$refs.avueLineForm.submit();
    },

    handleSubmit() {
      this.$refs.avueLineForm.validate((valid, done) => {
        if (valid) {
          this.dialogLoading = true;
          let regName = /^\d+(\.\d{1,2})?$/;
          if (regName.test(this.lineForm.cost)) {
            this.lineForm.files = JSON.stringify(this.fileArray);
            costLineSubmit(this.lineForm)
              .then((res) => {
                this.$message.success("操作成功");
                this.dialogVisible = false;
                this.page.currentPage = 1;
                this.getDetail(this.nodeData.id);
                this.onLoad(this.page);
                this.dialogLoading = false;
                done();
              })
              .catch(() => {
                this.dialogLoading = false;
                done();
              });
          } else {
            this.$message.warning("请正确填写金额");
            this.dialogLoading = false;
            done();
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.file-name {
  color: #6f91c1;
  cursor: pointer;
  text-decoration: underline;
}
.headInfo {
  padding: 15px 12px 30px 12px;
  font-size: 14px;
  color: #3f4448;
  label {
    width: 100px;
  }
}

.file-table {
  width: 100%;
  text-align: center;
  border-color: #cccccc;
  font-size: 14px;
  color: #606266;
}
.disUoloadSty {
  ::v-deep .el-upload--picture-card {
    display: none;
  }
  ::v-deep .el-upload__tip {
    display: none;
  }
  ::v-deep .el-upload--text {
    display: none;
  }
}
</style>
